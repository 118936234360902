@font-face {
	font-family: "Pretendard";
	font-display: fallback;
	src:
		local("Pretendard-Regular") url("./Pretendard-Regular.eot") format("embedded-opentype"),
		url("./Pretendard-Regular.woff2") format("font-woff2"),
		url("./Pretendard-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Pretendard";
	font-display: fallback;
	src:
		local("Pretendard-Medium") url("./Pretendard-Medium.eot") format("embedded-opentype"),
		url("./Pretendard-Medium.woff2") format("font-woff2"),
		url("./Pretendard-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Pretendard";
	font-display: fallback;
	src:
		local("Pretendard-SemiBold") url("./Pretendard-SemiBold.eot") format("embedded-opentype"),
		url("./Pretendard-SemiBold.woff2") format("font-woff2"),
		url("./Pretendard-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Pretendard";
	font-display: fallback;
	src:
		local("Pretendard-Bold") url("./Pretendard-Bold.eot") format("embedded-opentype"),
		url("./Pretendard-Bold.woff2") format("font-woff2"),
		url("./Pretendard-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Pretendard";
	font-display: fallback;
	src:
		local("Pretendard-ExtraBold") url("./Pretendard-ExtraBold.eot") format("embedded-opentype"),
		url("./Pretendard-ExtraBold.woff2") format("font-woff2"),
		url("./Pretendard-ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}
