/* 스크롤바 가로 사이즈 */
::-webkit-scrollbar {
	width: 15px;
}

/* 스크롤바 막대기 */
::-webkit-scrollbar-thumb {
	background-clip: padding-box;
	border: 3px solid transparent;
	border-left-width: 4px;
	border-radius: 16px;
	background-color: #c1c1c0;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #7d7d7d;
}

/* 스크롤바 백그라운드 */
/* ::-webkit-scrollbar-track {
	border: 1px solid #e2e2e3;
} */

:root {
	--main: #3e5ee0;
	--gray-medium: #cfcfcf;
	--gray-dark: #9b9b9b;
	--gray-extraDark: #2c2c2c;
}

* {
	line-height: 1.5;
	font-family: "Pretendard";
}

body {
	width: calc(100vw - 16px);
	overflow-y: scroll;
	color: var(--gray-extraDark);
}

body.dark-mode {
	background-color: #2c2c2c;
}

::selection {
	background: #ddd;
}

main {
	white-space: pre-wrap;
}

input {
	display: block;
	width: 100%;
	height: 42px;
	padding: 12px 16px;
	font-size: 14px;
	background-clip: padding-box;
	appearance: none;
	border: 1px solid var(--gray-dark);
	border-radius: 5px;
}

input:active {
	box-shadow: none;
}

input[type="radio"] {
	position: relative;
	bottom: 2px;
	width: 20px;
	height: 20px;
}

input[type="radio"]:checked {
	background-image: none;
	border: 2px solid #3e5ee0;
	box-shadow: inset 0px 0px 0px 2px white;
}

label {
	font-size: 0.8rem;
	font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

textarea,
input:disabled {
	border: 1px solid var(--gray-medium);
}

input::placeholder {
	font-size: 14px;
	font-weight: 400;
	color: var(--gray-dark);
	text-align: left;
}

textarea::placeholder {
	font-size: 12px;
	font-weight: 400;
	color: var(--gray-dark);
	text-align: left;
}

textarea {
	resize: none;
	width: 100%;
	height: 240px;
	padding: 16px;
	border: 1px solid var(--gray-medium);
	font-size: 0.875rem;
	font-weight: 400;
}

textarea:focus-visible {
	outline: var(--main) auto 1px;
}

a {
	font-weight: 500;
	text-decoration: none;
}

small {
	font-size: 1rem;
	font-weight: 500;
	color: var(--gray-medium);
}

em {
	font-style: normal;
}

ul {
	padding-left: 0;
	margin: 0;
	padding-inline-start: 0;
	margin-block-start: 0;
	margin-block-end: 0;
}

li {
	list-style: none;
}

button:focus {
	box-shadow: none !important;
}

dl,
dd {
	margin: 0;
}

legend {
	width: auto;
}

img {
	transform: translateZ(0);
	backface-visibility: hidden;
	image-rendering: -webkit-optimize-contrast;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
	image-rendering: crisp-edges;
}

#errors {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}

body.modal-wrapper-opened {
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
	white-space: pre-line;
}

@media (max-width: 1280px) {
	body {
		width: 100vw;
		overflow-x: hidden;
	}
}

.Toastify__toast-container {
	min-width: 400px;
	width: auto;
	bottom: 40px;
}

.Toastify__toast {
	min-height: 48px;
	padding: 0 16px;
	margin-bottom: 8px;
}

.Toastify__toast-body div {
	text-align: center;
	font-size: 14px;
}
